import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["form"]

  add_member(e) {
    e.preventDefault()
  }

  finalizeNewCommittee(element, reflex, noop, reflexId) {
    var e = document.getElementById('committee-form')
    e.hidden = false
    Velocity(e, { scale: 1, opacity: 1, display: "block" }, 200)
  }

  hideForm(element, reflex, noop, reflexId) {
    var e = document.getElementById('committee-form')
    Velocity(e, { scale: 0.01, opacity: 0, display: "none" }, { duration: 200 })
  }

}
