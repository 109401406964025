import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Student Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  finalizeUpdate(element) {
    Velocity(element, { scale: [1, 1.1], backgroundColor: [ "rgba(229, 231, 235, 1)", "rgba(green, 0.1)" ] }, 500)
    Velocity(element, { scale: 0.01, backgroundColor: [ "rgba(red, 0.5)", "rgba(green, 0.25)" ] }, 500)
    Velocity(element, { scale: 1 }, 600)
  }

  beforeReflex (element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
  }

}
