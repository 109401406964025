import ApplicationController from './application_controller'


/* This is the custom StimulusReflex controller for the AdvisorRelationships Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

export default class extends ApplicationController {
  static targets = ["control", "contents", "courseOfferingSource", "courseOffering"]

 hideForm(e) {
      return Velocity(this.contentsTarget,
        {
          display: "none",
          opacity: 0
        },
        { duration: 250 }
      ).then((elements) => {
        this.contentsTarget.classList.add('hidden')
        this.controlTarget.hidden = false
      }
      )
 }

 afterShowForm(e) {
      this.contentsTarget.classList.remove('hidden')
      Velocity(this.contentsTarget,
        {
          transform: ["scaleX(100%)", "scaleX(0)"],
          display: "block",
          opacity: 1
        }, 
        {
          duration: 200
        }
      )

      this.controlTarget.hidden = true
      const value = this.courseOfferingSourceTarget.value
      this.courseOfferingTarget.value = value
   }

   afterSelectOffering(e) {
      const value = this.courseOfferingSourceTarget.value
      this.courseOfferingTarget.value = value
   }

 afterUpdate(e) {
   var table_rows = document.getElementById('courses-table').rows
   var table_row_length = table_rows.length - 1
   var last_row = table_rows[table_row_length]
   this.hideForm(e).then( () => {
       Velocity(last_row, { scale: [1, 1.1], backgroundColor: [ "rgba(229, 231, 235, 1)", "rgba(green, 0.1)" ] }, 300)
       Velocity(last_row, { scale: 1 }, 400)
     }

   )
 }

 afterCreate(e) {
   var table_rows = document.getElementById('courses-table').rows
   var table_row_length = table_rows.length - 1
   var last_row = table_rows[table_row_length]
   this.hideForm(e).then(
     () => {
       Velocity(last_row, { scale: [1, 1.1], backgroundColor: [ "rgba(229, 231, 235, 1)", "rgba(green, 0.1)" ] }, 500)
       Velocity(last_row, { scale: 1 }, 600)
     }
   )
 }

}