import ApplicationController from './application_controller'
import { isVisible } from "helpers"


/* This is the custom StimulusReflex controller for the AdvisorRelationships Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

export default class extends ApplicationController {

  hideForm(element, reflex, noop, reflexId) {
    var form = document.getElementById('note-form')
    Velocity(form, 'slideOutLeft', 150)
  }

  finalizeShowForm(e) {
    var form = document.getElementById('note-form')
    Velocity(form, 'slideInLeft', 150)
  }

  finalizeEdit(e) {
    var form = document.getElementById('note-form')
    Velocity(form, 'slideInLeft', 150)
  }

  finalizeCreate(e) {
    var form = document.getElementById('note-form')
    Velocity(form, 'slideOutLeft', 150)
  }

  finalizeUpdate(e) {
    var form = document.getElementById('note-form')
    Velocity(form, 'slideOutLeft', 150)
  }


}