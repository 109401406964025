import ApplicationController from './application_controller'
import { isVisible } from "helpers"

export default class extends ApplicationController {
  static targets = ["reveal"]

  toggle_reveal(element, reflex, noop, reflexId) {
    if (isVisible(this.revealTarget)) {
      this.hide()
    } else {
      this.reveal()
    }
  }

  reveal(element, reflex, noop, reflexId) {
    Velocity(this.revealTarget, { scale: 1, opacity: 1, display: "block" }, { duration: 120 })
  }

  hide(element, reflex, noop, reflexId) {
    Velocity(this.revealTarget, { scale: 1, opacity: 0, display: "none" }, { duration: 120 })
  }

}