import ApplicationController from './application_controller'
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";

export default class extends ApplicationController {
  static targets = [ 'dzForm', 'dzSource', 'category', 'contents', 'landing' ];

  dzConnected() {
    this.dropZone = findDropZone(this.dzSourceTarget);
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  hidePreview() {
    var hideTarget = this.element.getAttribute("data-hide")
    var e = document.getElementById(hideTarget);
    Velocity(e, 'slideOutUp', 150)
  }

  hideFormInput() {
    this.dzFormTarget.hidden = true;
    // this.formTarget.style.display = "none";
  }

  showFormInput() {
    this.dzFormTarget.hidden = false;
    // this.formTarget.style.display = "none";
  }

  bindEvents() {

    this.dropZone.on("success", file => {
      this.showFormInput()
    });

    this.dropZone.on("error", file => {
      this.hideFormInput()
    });

    this.dropZone.on("removedfile", file => {
      this.hideFormInput()
    });

    this.dropZone.on("canceled", file => {
      this.hideFormInput()
    });
  }

  finalizeShow(element, reflex, noop, reflexId) { 
    var documents = document.getElementById('document-categories')
    if (documents.style.getPropertyValue('opacity') != '1') {
      Velocity(documents,
        {
          transform: ["rotateY(0)", "rotateY(90deg)", "scaleX(0)", "scaleX(400px)"],
          display: "block",
          opacity: 1
        },
        { duration: 300 }
      )
    }
  }

  finalizeShowFolders(element) {
    var documents = document.getElementById('document-categories')
    Velocity(documents,
      {
        transform: ["rotateY(90deg)", "rotateY(0)", "scaleX(0)", "scaleX(400px)"],
        display: "none",
        opacity: 0
      },
      { duration: 300 }
    )
  }

  finalizeDelete(e) {
    Velocity(this.landingTarget, 
      { opacity: 0,
        display: "none",
      },
      { duration: 100 }
    )
  }

  finalizeMilestonePreview(element) {
    var showTarget = this.element.getAttribute("data-hide")
    var e = document.getElementById(showTarget);
    Velocity(e, 'slideInUp', 150)
  }

  finalizeShowDocument(element) {
    Velocity(this.landingTarget, 
      { opacity: 1,
        display: "block",
      },
      { duration: 100 }
    )
  }

  beforeHideDocument(e) {
    Velocity(this.landingTarget, 
      { opacity: 0,
        display: "none",
      },
      { duration: 100 }
    )
  }

/*

var div = document.querySelector('div');
Velocity.RunSequence([
    { elements: div, properties: {translateX: '+=100'} },
    { elements: div, properties: 'transition.slideUpOut', options: {duration: 500} }
]);

*/


}

function findDropZone(source) {
  return new Dropzone.forElement(source);
}
