import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */

const sequenceFactory = (name, {steps = ['0%', '100%'], ...props} = {}, config = {}) => Velocity(
    'registerSequence',
// ➜ console.log(JSON.stringify(
    ['In', 'Out'].reduce((sequences, inOut) => ({ // build 'in', 'out' sequences …
        ...sequences,
        ...['Up', 'Down', 'Left', 'Right'].reduce( // … for each direction …
            (seq, direction) => ({
                ...seq,
                [`${name}${inOut}${direction}`]: Object.assign(// … with unique name. Merge default config …
                    steps.reduce(
                        (stepProps, step, i) => ({ // … with animation steps objects (0%, ➜ 100%) …
                            ...stepProps,
                            [step]: Object.fromEntries( //  … build animation step object …
                                Object.entries(props).map( // … from parameter matrix
                                    ([prop, row]) => (
                                        [
                                            prop,
                                            (cell => ( // check cell value …
                                                typeof cell === 'function' ? // … if cell has function …
                                                    cell({ // … call cell function and use returned value …
                                                        inOut,
                                                        direction
                                                    }) :
                                                    cell // … if cell has no function use plain cell value … (could be 'undefined')
                                            ))( // … ^^ invoke this with current row[colIndex]
                                                row[
                                                    inOut === 'In' ?
                                                        i :                 // … from matrix column …
                                                        row.length - 1 - i  // … or inverted matrix column …
                                                ]
                                            )
                                        ]
                                    )
                                ).filter(([prop, val]) => val !== undefined) // … filter props with empty cell value (see 'undefined' above)
                            )
                        }), {}
                    ),
                    config // default config
                )
            }), {}
        )
    }), {})
// ➜ , null, 2))
);

const axesByDirection = direction => (['Left', 'Right'].includes(direction) ? 'X' : 'Y');
const invertValueByDirection = (direction, value) => `${['Left', 'Up'].includes(direction) && value !== '0' ? '-' : ''}${value}`;
const translate = value => ({direction}) => `translate${axesByDirection(direction)}(${invertValueByDirection(direction, value)})`;
const slideProps = {
        steps:     ['0%',   '100%'],                // optional for 2 columns, mandatory for >2 columns
        opacity:   ['0',    '1'   ],
        transform: ['100%', '0'   ].map(translate), // some props might need a custom callback, e.g. to flip 100% -> -100%, or translateX -> translateY
        display:   ['none', 'block' ],
     // …n:        [from,   step1, step2, …, stepX]
};
sequenceFactory('slide', slideProps, {duration: 499, easing: 'easeOut'});

// create these slide + (Out || In) + (Up || Down || Left || Right)
// sequenceFactory('slide', slideProps, {duration: 499, easing: 'easeOut'});

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted (element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex (element, reflex, noop, reflexId) {
    document.body.classList.remove('wait')
    const focusElement = this.element.querySelector('[autofocus]')
    if (focusElement) {
      focusElement.focus()

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value
      focusElement.value = ''
      focusElement.value = value
    }
  }

  finalizeReflex (element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }
}
