import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = []

  dragstart(event) {
    event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-item-id"))
    event.dataTransfer.effectAllowed = "move"
  }

  dragover(event) {
    event.preventDefault()
    return true
  }
    
  dragenter(event) {
    event.preventDefault()
  }

  drop(event) {
    var data = event.dataTransfer.getData("application/drag-key")
    const dropTarget = event.target
    const draggedItem = this.element.querySelector(`[data-item-id='${data}']`);
    event.target.appendChild(draggedItem);
    event.preventDefault()
  }

  dragend(event) {
  }

}
