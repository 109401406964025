import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the AdvisorRelationships Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["control", "details"]

  hideForm(element, reflex, noop, reflexId) {
    var form = document.getElementById('advisorForm')
    var e = form.parentElement
    Velocity(e, 'slideOutUp', 150)
  }

  finalizeAddAdvisor(e) {
    var form = document.getElementById('advisorForm')
    e = form.parentElement
    Velocity(e, 'slideInUp', 150)
  }

  finalizeShow(e) {
    Velocity(this.detailsTarget,
      {
        transform: ["scaleY(100%)", "scaleY(0)"],
        display: "block",
        opacity: 1
      }, 
      {
        duration: 200
      }
    )
    Velocity(this.controlTarget, 
      { 
        display: "none",
        opacity: 0 
      },
      { duration: 200 }
    )
  }

  beforeHide(e) {
    Velocity(this.detailsTarget,
      {
        transform: ["scaleY(0%)", "scaleY(100%)"],
        display: "none",
        opacity: 0
      },
      {
        duration: 200
      }
    ).then((elements) =>
      this.detailsTarget.classList.add('hidden')
    )
    Velocity(this.controlTarget,
      { 
        display: "block",
        opacity: 1 
      },
      { duration: 200 }
    )
  }

  showForm(e) {
    Velocity(e, { scale: 1, opacity: 1, display: "block" }, { duration: 120 })
  }

  afterNewCs() {
    var e = document.getElementById('advisorFormStage2')
    this.showForm(e)
  }

  afterNewExternal() {
    var e = document.getElementById('advisorFormStage2')
    this.showForm(e)
  }

}
