import ApplicationController from './application_controller'
import { isVisible } from "helpers"

export default class extends ApplicationController {
  static targets = ['container']

  show() {
    if (this.hasContainerTarget) {
        Velocity(this.containerTarget, 'slideInUp')
    }
  }

  hide() {
    console.log(this.element.parentElement.parentElementt)
      Velocity(this.element.parentElement.parentElement, 'slideOutUp')
  }

}
