import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["form", "details"]

  add_member(e) {
    e.preventDefault()
  }

  showForm(element, reflex, noop, reflexId) {
    var e = document.getElementById('committee-member-form')
    Velocity(e, { scale: 0.01, opacity: 0, display: "none" }, { duration: 200 })
    Velocity(this.detailsTarget, 'slideInUp')
  }

}