import ApplicationController from './application_controller'

export default class extends ApplicationController {

  hideForm(element, reflex, noop, reflexId) {
    var form = document.getElementById('supportForm')
    var e = form.parentElement
    Velocity(e, 'slideOutUp', 150)
  }

  finalizeManageAccounts(element, reflex, noop, reflexId) {
    var form = document.getElementById('supportAccountsForm')
    Velocity(form, 'slideInUp', 150)
  }

  hideAccountsForm(element, reflex, noop, reflexId) {
    var form = document.getElementById('supportAccountsForm')
    Velocity(form, 'slideOutUp', 150)
  }

  finalizeDelete(e) {
    var form = document.getElementById('supportForm')
    e = form.parentElement
    Velocity(e, 'slideInUp', 150)
  }

  finalizeEdit(e) {
    var form = document.getElementById('supportForm')
    e = form.parentElement
    Velocity(e, 'slideInUp', 150)
  }

  edit(e) {
    Velocity(e, { scale: 1, opacity: 1, display: "block" }, { duration: 120 })
  }

}