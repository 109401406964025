import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
     if (!this.isPreview) {
       Velocity(this.element, 'slideInDown')
     }
    super.connect()
  }

  close() {
    Velocity(this.element, 'slideOutUp')
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview')
  }
}