import ApplicationController from './application_controller'

import List from "list.js"
export default class extends ApplicationController {
  static targets = ['list', 'visible', 'total']

  connect () {
    super.connect()
    var options = {
      valueNames: [
        { data: ['id'] },
        { name: 'value1', attr: 'data-value' },
        { name: 'value2', attr: 'data-value' },
        { name: 'value3', attr: 'data-value' },
        { name: 'value4', attr: 'data-value' },
        { name: 'value5', attr: 'data-value' },
        { name: 'value6', attr: 'data-value' },
        { name: 'value7', attr: 'data-value' }
      ],
      page: 17,
      pagination: [{
        paginationClass: "paginationBottom",
        item: "<li class='mx-1 list-none btn-group'><a class='btn btn-xs btn-primary page' href='#' onclick='return false;'></a></li>",
        innerWindow: 13,
        left: 5,
      }]
    };
    
    var hackerList = new List(this.listTarget, options);
    const total = this.totalTarget;
    const visible = this.visibleTarget;

    hackerList.on("updated", function(list) {
      total.innerHTML = list.items.length;
      visible.innerHTML = list.visibleItems.length;
    });
  }

  showResults() {

  }
}