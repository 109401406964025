import ApplicationController from './application_controller'
import { isVisible } from "helpers"


/* This is the custom StimulusReflex controller for the AdvisorRelationships Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

export default class extends ApplicationController {
  static targets = ["control", "contents"]

 toggle(e) {
    if (! this.contentsTarget.classList.contains('hidden')) {
      // this.contentsTarget.style.setProperty('animation-fill-mode', 'forward');
      // this.contentsTarget.style.setProperty('--animate-duration', '.7s');
      // this.contentsTarget.classList.add('animate__bounceOutLeft')
      // this.contentsTarget.classList.remove('animate__bounceInLeft')
      Velocity(this.contentsTarget,
        {
          transform: ["scaleX(0)", "scaleX(100%)"],
          display: "none",
          opacity: 0
        },
        { duration: 250 }
      ).then((elements) =>
        this.contentsTarget.classList.add('hidden')
      )
      Velocity(this.controlTarget,
        {
          transform: ["rotateY(0deg)", "rotateY(180deg)"]
        },
        {
          duration: 250
        }
      )
    } else {
      // this.contentsTarget.style.setProperty('animation-fill-mode', 'reverse');
      // this.contentsTarget.style.setProperty('--animate-duration', '.7s');
      // this.contentsTarget.classList.add('animate__bounceInLeft')
      // this.contentsTarget.classList.remove('animate__bounceOutLeft')
      this.contentsTarget.classList.remove('hidden')
      Velocity(this.contentsTarget,
        {
          transform: ["scaleX(100%)", "scaleX(0)"],
          display: "block",
          opacity: 1
        }, 
        {
          duration: 200
        }
      )
      Velocity(this.controlTarget,
        { transform: ["rotateY(180deg)", "rotateY(0deg)"]  },
        { duration: 200 }
      )
    }
  }

}